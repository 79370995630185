import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from 'src/common/utils';
import { createCable, removeCable } from 'src/components/Header/headerAction';
import { IAppState } from 'src/interface/IAppState';

interface Props {
  children: JSX.Element;
}

const ActionCableProvider = ({ children }: Props) => {
  const cable = useSelector((state: IAppState) => state.headerState.cable);
  const dispatch = useDispatch();

  const loadActionCable = async () => {
    const ActionCable = await import('actioncable');
    return ActionCable;
  };

  useEffect(() => {
    //Load Action Cable only when window is defined because it lib use window
    if (typeof window !== 'undefined' && !cable) {
      const handleCable = async () => {
        const ActionCable = await loadActionCable();
        const token = getToken();
        const wslink = process.env.NEXT_PUBLIC_WS_URL;
        const newCable = ActionCable.createConsumer(wslink + `?auth_token=${token}`);
        dispatch(createCable(newCable));
      };
      handleCable();
    }
    return () => {
      if (cable) {
        cable.disconnect();
        dispatch(removeCable());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, cable, typeof window]);

  return children;
};

export default ActionCableProvider;
