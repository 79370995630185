import React, { useEffect, useMemo } from 'react';
import Affix from 'antd/lib/affix';
import Layout from 'antd/lib/layout';
import message from 'antd/lib/message';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import AppVersion from 'src/app/AppVersion';
import APP_ROUTES from 'src/common/appRoutes';
import { resetError } from 'src/common/commonAction';
import { CONTAINER_MARGIN } from 'src/common/constants';
import EventDecoration from 'src/components/events/EventDecoration';
import Header from 'src/components/Header';
import { IAppState } from 'src/interface/IAppState';
import ActionCableProvider from 'src/providers/ActionCableProvider';
import { Flex } from 'src/styled/Layout';
const { Content } = Layout;

const key = 'updatable';

const AppLayout: React.FC = ({ children }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const commonState = useSelector((state: IAppState) => state.commonState);
  const infoUser = useSelector((state: IAppState) => state.headerState);

  const { isError, isLoading, isSuccess, errorMessage } = commonState;

  useEffect(() => {
    isLoading && message.loading({ content: 'Loading...', key });
    isSuccess && message.success({ content: 'Success', key });

    if (isError) {
      message.error({
        content: errorMessage || 'Something went wrong!',
        key
      });
      dispatch(resetError());
    }
  }, [isError, isLoading, isSuccess, dispatch, errorMessage]);

  const isPageHasTabs = useMemo(() => {
    return (
      router.pathname.includes(APP_ROUTES.ACTIVITY) ||
      router.pathname.includes(APP_ROUTES.PROFILE) ||
      router.pathname.includes(APP_ROUTES.REWARD) ||
      router.pathname.includes(APP_ROUTES.ORG_CHART)
    );
  }, [router]);

  return (
    <ActionCableProvider>
      <Layout>
        <EventDecoration {...infoUser} />
        <Header />
        <Layout id="id-body" className="main-container">
          <Content
            style={{
              padding: `20px ${isPageHasTabs ? '0' : `${CONTAINER_MARGIN * 100}%`}`
            }}>
            {children}
          </Content>

          <Affix
            offsetBottom={5}
            style={{
              alignSelf: 'flex-end',
              width: 200,
              position: 'fixed',
              bottom: 0,
              right: 0
            }}>
            <Flex full jusend>
              <AppVersion />
            </Flex>
          </Affix>
        </Layout>
      </Layout>
    </ActionCableProvider>
  );
};

export default AppLayout;
