import React, { useMemo } from 'react';
import Layout from 'antd/lib/layout';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import APP_ROUTES from 'src/common/appRoutes';
import { CONTAINER_MARGIN } from 'src/common/constants';
import { IAppState } from 'src/interface/IAppState';
import { IeventState } from 'src/interface/IEventState';
import { imgs } from 'src/static';
import { Menu as M, Image as I } from 'src/styled/Component';

const RightSideHeaderClient = dynamic(() => import('src/components/Header/RightSideHeaderClient'), {
  ssr: false
});

const menu = [
  {
    name: 'Dashboard',
    link: APP_ROUTES.HOME
  },
  {
    name: 'Portal',
    link: APP_ROUTES.PORTAL
  },
  {
    name: 'Heatmap',
    link: APP_ROUTES.HEATMAP
  },
  {
    name: 'Activities',
    link: APP_ROUTES.ACTIVITY_ANNOUNCEMENTS
  },
  {
    name: 'Org Chart',
    link: APP_ROUTES.ORG_CHART
  },
  {
    name: 'Q&A',
    link: APP_ROUTES.QA
  }
];

const Header: React.FC = () => {
  const router = useRouter();
  const eventData: IeventState = useSelector((state: IAppState) => state.eventState);
  const { pathname } = router;

  const backgroundImage = useMemo(
    () => eventData.listDataEvent.find((item) => item.slug === 'top'),
    [eventData.listDataEvent]
  );

  const renderMenu = () => {
    return menu.map((item) => {
      return (
        <M.Item
          key={item.link}
          active={
            pathname === item.link ||
            (pathname.includes(APP_ROUTES.ACTIVITY) && item.name === 'Activities')
          }
          onClick={() => router.push(item.link)}>
          {item.name}
        </M.Item>
      );
    });
  };

  return (
    <Layout.Header
      style={{
        backgroundColor: '#1F2D3A',
        padding: `12px ${CONTAINER_MARGIN * 100}%`,
        lineHeight: '32px',
        backgroundImage: backgroundImage ? `url(${backgroundImage.image_url})` : 'none',
        backgroundSize: '20%'
      }}>
      <M.WrapContentMenu>
        <div id={'left-menu'}>
          <Link href="/">
            <a style={{ display: 'flex', marginRight: '30px' }}>
              <I.Image w={150} src={imgs.LogoHeader} />
            </a>
          </Link>
          <M.Menu>{renderMenu()}</M.Menu>
        </div>
        <div id={'right-menu'}>
          <RightSideHeaderClient />
        </div>
      </M.WrapContentMenu>
    </Layout.Header>
  );
};

export default Header;
