import { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { message, Spin } from 'antd';
import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import APP_ROUTES from 'src/common/appRoutes';
import { setSuccess } from 'src/common/commonAction';
import { getDataProfileFall, requestDataProfile } from 'src/components/Header/headerAction';
import AppLayout from 'src/components/Layout';
import { actionTypes as actionTypesSignIn } from 'src/components/signin/signinAction';
import { IAppState } from 'src/interface/IAppState';
import { Flex } from 'src/styled/Layout';

interface Props {
  children: JSX.Element;
}

const key = 'updatable';

const AuthProvider = ({ children }: Props) => {
  const router = useRouter();
  const { data: session, status } = useSession();
  const [verified, setVerified] = useState(false);
  const isLoading = useSelector((state: IAppState) => state.headerState.isLoading);
  const isSigning = useSelector((state: IAppState) => state.signinState.isLoading);
  const dispatch = useDispatch();
  const userId = useSelector((state: IAppState) => state.headerState.data.id);

  useEffect(() => {
    if (isLoading) return;
    if (!userId) {
      if (typeof window !== 'undefined') {
        signOut({
          callbackUrl: APP_ROUTES.SIGN_IN
        });
        if (window) localStorage.clear();
      }
      router.replace(APP_ROUTES.SIGN_IN);
      message.error({
        content: 'Something went wrong!',
        key
      });
      return;
    }
    setVerified(true);
  }, [isLoading, router, userId]);

  useEffect(() => {
    if (status === 'loading') return;
    if (session && session.dataUser) {
      dispatch({ type: actionTypesSignIn.POST_AUTH, values: session });
      dispatch(setSuccess());
    } else {
      dispatch({ type: actionTypesSignIn.LOGIN_FAIL });
    }
    if (isSigning) return;
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(getDataProfileFall());
      router.replace(APP_ROUTES.SIGN_IN);
    }
    dispatch(requestDataProfile());
  }, [dispatch, isSigning, router, session, status]);

  if (verified) return <AppLayout>{children}</AppLayout>;

  return (
    <Flex center full style={{ height: '100vh' }}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
    </Flex>
  );
};

export default AuthProvider;
